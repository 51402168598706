require('inputmask');
window.SimpleMaskMoney = require('simple-mask-money');
window.VMasker = require('vanilla-masker/lib/vanilla-masker');

window.Inputmask.extendAliases({
    'cpf': {
        numeric: true,
        mask: '999.999.999-99',
        clearMaskOnLostFocus: true,
        showMaskOnFocus: false,
        showMaskOnHover: false,
        clearIncomplete: true
    },
    'cnpj': {
        mask: '99.999.999/9999-99',
        clearMaskOnLostFocus: true,
        showMaskOnFocus: false,
        showMaskOnHover: false,
        clearIncomplete: true
    },
    'cpf_cnpj': {
        mask: ['999.999.999-99', '99.999.999/9999-99'],
        clearMaskOnLostFocus: true,
        showMaskOnFocus: false,
        showMaskOnHover: false,
        clearIncomplete: true
    },
    'zip_code': {
        mask: '99999-999',
        clearMaskOnLostFocus: true,
        showMaskOnFocus: false,
        showMaskOnHover: false,
        clearIncomplete: true,
        autoUnmask: true
    },
    'phone': {
        mask: ['(99) 9999-9999', '(99) 9 9999-9999'],
        clearMaskOnLostFocus: true,
        showMaskOnFocus: false,
        showMaskOnHover: false,
        clearIncomplete: true,
    },
    'cell': {
        mask: '(99) 9 9999-9999',
        clearMaskOnLostFocus: true,
        showMaskOnFocus: false,
        showMaskOnHover: false,
        clearIncomplete: true,
    },
    'date': {
        mask: '99/99/9999',
        clearMaskOnLostFocus: true,
        showMaskOnFocus: false,
        showMaskOnHover: false,
        clearIncomplete: true,
    },
});
